import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "friday-4th-of-december-2020",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#friday-4th-of-december-2020",
        "aria-label": "friday 4th of december 2020 permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Friday 4th of December 2020`}</h1>
    <p>{`It’s Friday, December 4 of the longest year. It’s 43 days after Polish
`}<del parentName="p">{`Constitutional`}</del>{` Court ruled to make almost all cases of abortion illegal
and sparked the Women’s Strike, the biggest wave of protests in Poland since
the revolution of 1989 and the end of Polish People’s Republic.`}</p>
    <p>{`It’s also my 2nd engagement anniversary, and the day when I finally found
some time for Advent of Code.`}</p>
    <p>{`My goal for the challenge is to learn something new. I’m highly interested
in unconventional approaches to programming, structured editors, and
bundling of concerns.`}</p>
    <p>{`I’ll use this year’s Advent of Code to learn some new stuff.`}</p>
    <p>{`You can see a my solutions in `}<a parentName="p" {...{
        "href": "/notes/advent-of-code-2020",
        "title": "advent-of-code-2020"
      }}>{`advent-of-code-2020`}</a>{` note.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      